import Legend from 'components/legend'
import IconButton from '@mui/material/IconButton'
import { useAppDispatch, useAppSelector } from 'utils'
import { togglePanel } from 'reducers/projects/slices'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import './style.scss'

export default function Editor() {
  const dispatch = useAppDispatch()
  const { panel } = useAppSelector(state => state.projects)

  return (
    <div className={`editor ${panel ? 'panel' : 'panel-close'}`}>
      <Legend />
      <div className="button-toggle-panel">
        <IconButton
          className="customHoverFocus"
          onClick={() => dispatch(togglePanel())}
        >
          {panel ? <ArrowBackIosNewIcon sx={{ color: 'white' }} />
            : <ArrowForwardIosIcon sx={{ color: 'white' }} />}
        </IconButton>
      </div>
    </div>
  )
}

Editor.defaultProps = {
  object: [],
}
