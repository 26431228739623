import { ReactElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useAppDispatch } from 'utils'
import { Permission, Project } from 'types'
import terms from 'common/terms'
import SimpleButton from 'components/button'
import { updatedataMap, updatedataTable } from 'reducers/projects/slices'
import DropDown, { DropDownType } from 'components/dropDown/DropDown'
import MenuDropdown from 'components/menuDropDown/MenuDropdown'
import map2025 from 'assets/data/chantiers_2025_map.json'
import table2025 from 'assets/data/chantiers_2025.json'
import map2024 from 'assets/data/chantiers_2024_map.json'
import table2024 from 'assets/data/chantiers_2024.json'

import './style.scss'

type Props = {
  project: Project
}

function Card({ project }: Props): ReactElement {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const dropdownActions = [
    {
      label: 'Favoris',
      handleClick: () => { /* TODO: Implement */ },
    },
    {
      label: 'Dupliquer',
      handleClick: () => { /* TODO: Implement */ },
    },
    {
      label: 'Modifier',
      handleClick: () => { /* TODO: Implement */ },
    },
    {
      label: 'Supprimer',
      handleClick: () => { /* TODO: Implement */ },
    },
  ]

  const handleNavigation = () => {
    navigate(`/portefeuilles/${project.slug}`)
    if (project.slug === '2024') {
      dispatch(updatedataMap(map2024))
      dispatch(updatedataTable(table2024))
    } else {
      dispatch(updatedataMap(map2025))
      dispatch(updatedataTable(table2025))
    }
  }

  return (
    <div className="card-project">
      <DropDown
        type={DropDownType.DOTS}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      >
        <MenuDropdown actions={dropdownActions} handleClose={() => setAnchorEl(null)} />
      </DropDown>
      <h2>{project.name}</h2>
      <p>
        Petite description
      </p>
      <span>{`${terms.Common.updated} : ${moment(project.last_modified).format('DD/MM/YYYY')}`}</span>
      <SimpleButton text={terms.Pages.Projects.Cards.actionButton} onClick={handleNavigation} />
    </div>
  )
}

export default Card
