export interface Project {
  name: string
  slug: string
  last_modified: string
  favorite: boolean
}

export interface ProjectViewer extends Project {
  rendered_configuration: Configuration
}

export interface ProjectEditor extends Project {
  templatable_configuration: Configuration
  parameters_groups: ParametersGroup[]
  shared_with: SharedWith[]
}

export interface Configuration {
  layers: Layer[]
}

export interface Layer {
  [key: string]: {
    props: {
      type: string
    }
  }
}

export interface ParametersGroup {
  name: string
  order: number
  parameters: Parameter[]
}

export interface Parameter {
  id: string
  name: string
  type: string
  value: string
}

export interface SharedWith {
  user_id: string
  permission: Permission
}

export enum Permission {
  READ_ONLY = 'Lecture',
  READ_WRITE = 'Écriture',
}
