/* eslint-disable no-return-assign */
import './style.scss'
import {
  useState,
} from 'react'
import {
  DataGridPro, frFR, GridColDef,
} from '@mui/x-data-grid-pro'
import { useAppDispatch, useAppSelector } from 'utils'
import { updateObject, updateObjectFiltered } from 'reducers/map/slices'
import { ActivePanel } from 'types'
import { changePanel, updateFilters } from 'reducers/projects/slices'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { frFR as pickersFrFR } from '@mui/x-date-pickers/locales'
import { frFR as coreFrFR } from '@mui/material/locale'
import Button from '@mui/material/Button'
import _ from 'lodash'

const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  frFR,
  pickersFrFR,
  coreFrFR,
)

export default function Config() {
  const dispatch = useAppDispatch()
  const { dataTable, dataMap, filters } = useAppSelector(state => state.projects)
  console.log(filters)
  const newArray = Object.keys(dataTable[0])
  const columndata = []
  for (let i = 0; i < newArray.length; i += 1) {
    const field = newArray[i]
    const headerName = newArray[i]
    const type = 'text'
    const width = 200
    const editable = true
    columndata.push({
      field, headerName, type, width, editable,
    })
  }

  const cellSelect = {
    field: 'button',
    headerName: 'Name 6',
    width: 100,
    renderCell: params => {
      const onClick = e => {
        e.stopPropagation()
        const object = dataMap.features.find(obj => obj.properties.id === params.id)
        dispatch(updateObject(object))
        dispatch(changePanel(ActivePanel.CODE))
      }

      return <Button onClick={onClick}>Voir</Button>
    },
  }

  columndata.unshift(cellSelect)
  const columns: GridColDef[] = columndata
  return (
    <div className="config">
      <ThemeProvider theme={theme}>
        <DataGridPro
          onStateChange={e => {
            const filtered = Object.keys(e.visibleRowsLookup).filter(key => e.visibleRowsLookup[key])
            dispatch(updateObjectFiltered(filtered))
          }}
          onFilterModelChange={e => {
            dispatch(updateFilters(e.items))
          }}
          rows={dataTable}
          columns={columns}
          pagination
          filterModel={{
            items: filters,
          }}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
            filter: {
              filterModel: {
                items: filters,
              },
            },
          }}
        />
      </ThemeProvider>
    </div>
  )
}
