import { LAYER_NAMES } from '../const'
import { LayerProperties } from '../types'

const chantierLayerPaint: LayerProperties = {
  lineLayer: {
    type: 'line',
    id: LAYER_NAMES.chantierLayer,
    layout: { 'line-cap': 'round', 'line-join': 'round' },
    paint: {},
  },
  pointLayer: {
    type: 'circle',
    id: `${LAYER_NAMES.chantierLayer}-point`,
    paint: {},
  },
  legendLayer: {
    type: 'symbol',
    id: `${LAYER_NAMES.chantierLayer}-symbol`,
    layout: {
      'symbol-placement': 'point',
      'text-font': ['Open Sans Regular'],
      'text-field': '{act_ref}',
      'text-size': 32,
    },
    paint: {},
  },
}

export default chantierLayerPaint
