import './style.scss'

type Props = {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  action: () => void
  active?: boolean
}

export default function Action({ Icon, action, active }: Props) {
  return (
    <div className={`flex-center icon-action${active ? ' active' : ''}`} onClick={action}>
      <Icon />
    </div>
  )
}

Action.defaultProps = {
  active: false,
}
