import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ViewportProps } from 'react-map-gl'

export type MapState = {
  viewport: ViewportProps
  selectedRegionId: string | null,
  displayToolbar: string
  lineWidth: number[]
  pointWidth: number[]
  object: any
  objectFiltered: any
}

const initialState: MapState = {
  viewport: {
    latitude: 46.8025,
    longitude: 2.7643,
    zoom: 5.4389429465554,
    bearing: 0,
    pitch: 0,
  },
  selectedRegionId: null,
  displayToolbar: '',
  lineWidth: [3, 12],
  pointWidth: [3, 12],
  object: null,
  objectFiltered: [],
}

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    updateViewport: (state, action: PayloadAction<ViewportProps>) => {
      state.viewport = action.payload
    },
    setSelectRegionId: (state, action: PayloadAction<string | null>) => {
      state.selectedRegionId = action.payload
    },
    toggleDisplayToolbar: (state, action: PayloadAction<string>) => {
      state.displayToolbar = action.payload
    },
    updateLineWidth: (state, action: PayloadAction<number[]>) => {
      state.lineWidth = action.payload
    },
    updatePointWidth: (state, action: PayloadAction<number[]>) => {
      state.pointWidth = action.payload
    },
    updateObject: (state, action: PayloadAction<any>) => {
      state.object = action.payload
    },
    updateObjectFiltered: (state, action: PayloadAction<any>) => {
      state.objectFiltered = action.payload
    },

  },
})

export const {
  updateViewport,
  setSelectRegionId,
  toggleDisplayToolbar,
  updateLineWidth,
  updatePointWidth,
  updateObject,
  updateObjectFiltered,
} = mapSlice.actions

export default mapSlice.reducer
