import { ChangeEvent, useRef, useState } from 'react'
import searchIcon from 'assets/icons/search.svg'
import './style.scss'

type Props = {
  onChange: (e: string) => void
  placeholder: string
}
export default function SearchBar({ onChange, placeholder }: Props) {
  const [value, setValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setValue(target.value)
    onChange(target.value)
  }

  return (
    <div className="search-bar">
      <img src={searchIcon} alt="search-action" />
      <input
        ref={inputRef}
        type="text"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  )
}
