/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  Dispatch, ReactElement, SetStateAction,
} from 'react'
import Menu from '@mui/material/Menu'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import './dropDown.scss'

interface DropDownProps {
  type: DropDownType;
  children: ReactElement[] | ReactElement;
  anchorEl: HTMLElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement>>;
  label?: string;
}

export enum DropDownType {
  DOTS = 'dots',
  CHEVRON = 'chevron',
}

function DropDown({
  type, children, anchorEl, setAnchorEl, label,
}: DropDownProps): ReactElement {
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={`simple-dropdown dropdown--${type}`}>
      {label && <span className="label">{label}</span>}
      <button
        className="drop-down-button"
        type="button"
        onClick={handleClick}
      >
        {type === DropDownType.DOTS ? <MoreHorizIcon /> : <ExpandMoreIcon />}
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {children}
      </Menu>
    </div>
  )
}

DropDown.defaultProps = {
  label: '',
}

export default DropDown
