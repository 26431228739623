import { ReactElement, useState } from 'react'
import { updateLineWidth, updatePointWidth } from 'reducers/map/slices'
import { useAppDispatch, useAppSelector } from 'utils'

import Slider from '@mui/material/Slider'
import './style.scss'

export default function MapStyleMenu(): ReactElement {
  const dispatch = useAppDispatch()
  const { lineWidth, pointWidth } = useAppSelector(state => state.map)
  const handleChangeLineWidth = (event: Event, newValue: number | number[]) => {
    dispatch(updateLineWidth(newValue as number[]))
  }

  const handleChangePointWidth = (event: Event, newValue: number | number[]) => {
    dispatch(updatePointWidth(newValue as number[]))
  }

  return (
    <div className="wrapper">
      <div className="category">Chantiers lignes</div>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={lineWidth}
        min={1}
        max={15}
        step={0.5}
        onChange={handleChangeLineWidth}
        valueLabelDisplay="auto"
      />
      <div className="category">Chantiers points</div>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={pointWidth}
        min={1}
        max={15}
        step={0.5}
        onChange={handleChangePointWidth}
        valueLabelDisplay="auto"
      />
    </div>
  )
}
