import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Project, ActivePanel } from 'types'

export type ProjectState = {
  list: Project[]
  panel: boolean
  dataMap: any
  dataTable: any
  onglet: ActivePanel
  filters: []
}

const initialState: ProjectState = {
  list: [
    {
      name: 'Principaux chantiers 2024 - France',
      slug: '2024',
      last_modified: '2023-12-04T13:51:46.620982Z',
      favorite: false,
    },
    {
      name: 'Principaux chantiers 2025 - France',
      slug: '2025',
      last_modified: '2023-12-05T15:41:57.361856Z',
      favorite: false,
    },
  ],
  dataMap: null,
  dataTable: null,
  panel: true,
  onglet: ActivePanel.CODE,
  filters: [],
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjects(state, action: PayloadAction<Project[]>) {
      state.list = action.payload
    },
    updateProject(state, action: PayloadAction<Project>) {
      const projectIndex = state.list.findIndex(project => project.slug === action.payload.slug)
      if (projectIndex === -1) {
        state.list.push(action.payload)
        return
      }
      state.list[projectIndex] = action.payload
    },
    togglePanel(state) {
      state.panel = !state.panel
    },
    changePanel(state, action: PayloadAction<ActivePanel>) {
      state.onglet = action.payload
    },
    updatedataMap(state, action: PayloadAction<any>) {
      state.dataMap = action.payload
    },
    updatedataTable(state, action: PayloadAction<any>) {
      state.dataTable = action.payload
    },
    updateFilters(state, action: PayloadAction<any>) {
      state.filters = action.payload
    },
  },
})

export const {
  setProjects,
  updateProject,
  togglePanel,
  changePanel,
  updatedataMap,
  updatedataTable,
  updateFilters,
} = projectsSlice.actions

export default projectsSlice.reducer
