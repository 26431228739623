import { FAMILY_CONSTRUCTIONS } from 'components/layers/const'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'

const colorAtt = (id: string) => {
  switch (id) {
    case FAMILY_CONSTRUCTIONS.adv.label:
      return FAMILY_CONSTRUCTIONS.adv.color
    case FAMILY_CONSTRUCTIONS.development.label:
      return FAMILY_CONSTRUCTIONS.development.color
    case FAMILY_CONSTRUCTIONS.station.label:
      return FAMILY_CONSTRUCTIONS.station.color
    case FAMILY_CONSTRUCTIONS.electric.label:
      return FAMILY_CONSTRUCTIONS.electric.color
    case FAMILY_CONSTRUCTIONS.environment.label:
      return FAMILY_CONSTRUCTIONS.environment.color
    case FAMILY_CONSTRUCTIONS.structure.label:
      return FAMILY_CONSTRUCTIONS.structure.color
    case FAMILY_CONSTRUCTIONS.maintenance.label:
      return FAMILY_CONSTRUCTIONS.maintenance.color
    case FAMILY_CONSTRUCTIONS.landStructure.label:
      return FAMILY_CONSTRUCTIONS.landStructure.color
    case FAMILY_CONSTRUCTIONS.handicap.label:
      return FAMILY_CONSTRUCTIONS.handicap.color
    case FAMILY_CONSTRUCTIONS.signal.label:
      return FAMILY_CONSTRUCTIONS.signal.color
    case FAMILY_CONSTRUCTIONS.reliability.label:
      return FAMILY_CONSTRUCTIONS.reliability.color
    case FAMILY_CONSTRUCTIONS.security.label:
      return FAMILY_CONSTRUCTIONS.security.color
    case FAMILY_CONSTRUCTIONS.telecom.color:
      return FAMILY_CONSTRUCTIONS.telecom.label
    case FAMILY_CONSTRUCTIONS.track.label:
      return FAMILY_CONSTRUCTIONS.track.color
    default:
      return '#154734'
  }
}

const iconAtt = (id: string) => {
  switch (id) {
    case FAMILY_CONSTRUCTIONS.adv.label:
      return FAMILY_CONSTRUCTIONS.adv.logo
    case FAMILY_CONSTRUCTIONS.development.label:
      return FAMILY_CONSTRUCTIONS.development.logo
    case FAMILY_CONSTRUCTIONS.station.label:
      return FAMILY_CONSTRUCTIONS.station.logo
    case FAMILY_CONSTRUCTIONS.electric.label:
      return FAMILY_CONSTRUCTIONS.electric.logo
    case FAMILY_CONSTRUCTIONS.environment.label:
      return FAMILY_CONSTRUCTIONS.environment.logo
    case FAMILY_CONSTRUCTIONS.structure.label:
      return FAMILY_CONSTRUCTIONS.structure.logo
    case FAMILY_CONSTRUCTIONS.maintenance.label:
      return FAMILY_CONSTRUCTIONS.maintenance.logo
    case FAMILY_CONSTRUCTIONS.landStructure.label:
      return FAMILY_CONSTRUCTIONS.landStructure.logo
    case FAMILY_CONSTRUCTIONS.handicap.label:
      return FAMILY_CONSTRUCTIONS.handicap.logo
    case FAMILY_CONSTRUCTIONS.signal.label:
      return FAMILY_CONSTRUCTIONS.signal.logo
    case FAMILY_CONSTRUCTIONS.reliability.label:
      return FAMILY_CONSTRUCTIONS.reliability.logo
    case FAMILY_CONSTRUCTIONS.security.label:
      return FAMILY_CONSTRUCTIONS.security.logo
    case FAMILY_CONSTRUCTIONS.telecom.label:
      return FAMILY_CONSTRUCTIONS.telecom.logo
    case FAMILY_CONSTRUCTIONS.track.label:
      return FAMILY_CONSTRUCTIONS.track.logo
    default:
      return <QuestionMarkIcon />
  }
}

export {
  colorAtt, iconAtt,
}
