import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import terms from 'common/terms'
import { useAppSelector } from 'utils'
import eyeIcon from 'assets/icons/eye.svg'
import helpIcon from 'assets/icons/help.svg'
import './style.scss'

function SideMenu(): ReactElement {
  const navigate = useNavigate()
  const { sideMenuOpened } = useAppSelector(state => state.app)

  return (
    <div className={`side-menu${sideMenuOpened ? ' opened' : ''}`}>
      <a className="active" onClick={() => navigate('/projects')}>
        <img src={eyeIcon} alt="eye-icon" />
        <span>{terms.SideMenu.links.myViz}</span>
      </a>
      <button type="button" onClick={() => { /* TODO: Implement */ }}>
        <img src={helpIcon} alt="help-icon" />
        <span>{terms.SideMenu.links.help}</span>
      </button>
    </div>
  )
}

export default SideMenu
