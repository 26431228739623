/* eslint-disable max-len */
import { ReactElement, useState } from 'react'
import { MapEvent, Popup } from 'react-map-gl'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import moment from 'moment'
import 'moment/locale/fr'
import './style.scss'
import nextId from 'react-id-generator'
import { colorAtt, iconAtt } from 'components/legend/utils'

type Props = {
    event: MapEvent;
    onClose: (state: boolean) => void;
}
export default function FeatureClickPopup({ event, onClose }: Props): ReactElement {
  const coords = event?.lngLat
  const features = event?.features

  const [expanded, setExpanded] = useState<string | false>('panel1')

  const handleChange = (panel: string) => (events: React.SyntheticEvent, newExpanded: boolean) => {
    event.stopPropagation()
    setExpanded(newExpanded ? panel : false)
  }

  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `0px solid ${theme.palette.divider}`,
    background: 'black',
    color: 'white',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&:last-child': {
      borderBottomRightRadius: '12px',
      borderBottomLeftRadius: '12px',
    },
    '&:first-child': {
      borderTopRightRadius: '12px',
      borderTopLeftRadius: '12px',
    },
  }))

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    flexDirection: 'row',
    minHeight: '26px',
    paddingLeft: 0,
    '& .MuiAccordionSummary-expandIconWrapper': {
      color: 'white',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(0),
      margin: 2,
    },
  }))

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    background: 'white',
    color: 'black',
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  }))

  return (
    <Popup
      longitude={coords[0]}
      latitude={coords[1]}
      captureScroll
      onClose={() => onClose(false)}
      className="mapboxgl-hover-custom-popup"
    >
      <div className="mapboxgl-popup-container">
        <div className="popup-content">

          {features.map(feat => (
            <Accordion
              expanded={expanded === feat.properties.id}
              onChange={handleChange(feat.properties.id)}
              key={nextId()}
            >
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="summary">
                <div className="title">
                  {feat.properties.nom_gare === 'null' ? 'Nom de gare inconnue' : feat.properties.nom_gare}
                </div>
              </AccordionSummary>
              {expanded === feat.properties.id && (
              <AccordionDetails>
                <div className="item">
                  <div className="description-wrapper">
                    <span className="description">
                      Type:
                      {' '}
                      {feat.properties.famille}
                    </span>
                    <span className="description">
                      Région:
                      {' '}
                      {feat.properties.region_sncf}
                    </span>
                    <span className="timeline">
                      Date:
                      {' '}
                      { feat.properties.date_fin !== 'null' ? `${moment(feat.properties.date_debut).format('DD MMMM YYYY')}` : ''}
                      {' '}
                      { feat.properties.date_fin !== 'null' ? 'au' : ''}
                      {' '}
                      { feat.properties.date_fin !== 'null' ? `${moment(feat.properties.date_fin).format('DD MMMM YYYY')}` : ''}
                    </span>
                    <span className="description">
                      Budget:
                      {' '}
                      {`${Intl.NumberFormat('fr', { notation: 'compact' }).format(feat.properties.budget)}`}
                      €
                    </span>
                  </div>
                </div>
              </AccordionDetails>
              )}
            </Accordion>
          ))}
        </div>

      </div>

    </Popup>
  )
}
