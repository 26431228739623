import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum SnackbarSeverity {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

interface AppState {
  error: null | undefined,
  fullscreen: boolean,
  displaySnackbar: boolean,
  snackbarMessage: string,
  snackbarSeverity: SnackbarSeverity,
  sideMenuOpened: boolean,
}

const initialState: AppState = {
  error: null,
  fullscreen: false,
  displaySnackbar: false,
  snackbarMessage: '',
  snackbarSeverity: SnackbarSeverity.SUCCESS,
  sideMenuOpened: window.location.pathname === '/' || window.location.pathname === '/projects',
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleFullscreen: state => {
      state.fullscreen = !state.fullscreen
    },
    toggleSideMenu: state => {
      state.sideMenuOpened = !state.sideMenuOpened
    },
    closeSideMenu: state => {
      state.sideMenuOpened = false
    },
    getError: (state, action: PayloadAction<null>) => {
      state.error = action.payload
    },
    setSnackbar: (state, action: PayloadAction<{ message: string, severity: SnackbarSeverity }>) => {
      state.displaySnackbar = true
      state.snackbarMessage = action.payload.message
      state.snackbarSeverity = action.payload.severity
    },
    hideSnackbar: state => {
      state.displaySnackbar = false
      state.snackbarMessage = ''
      state.snackbarSeverity = SnackbarSeverity.SUCCESS
    },
  },
})

export const {
  toggleFullscreen,
  toggleSideMenu,
  closeSideMenu,
  getError,
  setSnackbar,
  hideSnackbar,
} = appSlice.actions

export default appSlice.reducer
