import adminRegion from 'assets/mapstyles/region_admin.json'
import { useAppDispatch, useAppSelector } from 'utils'
import { updateViewport } from 'reducers/map/slices'
import {
  Feature, FeatureCollection, MultiPolygon, Polygon,
} from 'geojson'
import {
  bbox, mask, polygon,
} from '@turf/turf'
import { ReactElement, useEffect, useState } from 'react'
import {
  FlyToInterpolator, Layer, Source, WebMercatorViewport,
} from 'react-map-gl'

import regionLayerPaint from './paint'

export default function RegionLayer(): ReactElement {
  const dispatch = useAppDispatch()
  const { viewport, selectedRegionId } = useAppSelector(state => state.map)
  const [regionMask, setRegionMask] = useState<Feature<Polygon> | undefined>()

  useEffect(() => {
    const region = (adminRegion as FeatureCollection<Polygon | MultiPolygon>).features
      .find(feature => feature.properties?.libgeo === selectedRegionId)
    // setSelectedRegion(region)
    if (region) {
      // console.log(selectedRegion)
      const regionBbox = bbox(region)
      const { longitude, latitude, zoom } = new WebMercatorViewport(viewport as { width: number, height: number })
        .fitBounds([[regionBbox[0], regionBbox[1]], [regionBbox[2], regionBbox[3]]], {
          padding: {
            top: 20, left: 300, right: 20, bottom: 20,
          },
        })
      dispatch(updateViewport({
        ...viewport,
        longitude,
        latitude,
        zoom,
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator(),
      }))
      const regionPolygons = region.geometry.type === 'Polygon' ? [region as Feature<Polygon>]
        : region.geometry.coordinates.map(poly => polygon(poly))
      const worldMask = polygon([[[-180, -90], [180, -90], [180, 90], [-180, 90], [-180, -90]]])
      setRegionMask(regionPolygons.reduce((acc, poly) => mask(poly, acc), worldMask))
    } else {
      setRegionMask(undefined)
    }
  }, [selectedRegionId])

  return (
    <>
      <Source
        type="geojson"
        data={adminRegion as FeatureCollection}
      >
        <Layer {...regionLayerPaint.regionLayer} />
        <Layer {...regionLayerPaint.regionHighlightLayer} filter={['==', 'libgeo', selectedRegionId || '']} />
      </Source>
      <Source
        id="polygons-source"
        type="geojson"
        data={regionMask || {
          type: 'FeatureCollection',
          features: [],
        }}
      >
        <Layer
          id="polygons"
          type="fill"
          source="polygons-source"
          paint={{ 'fill-color': 'gray', 'fill-opacity': 0.2 }}
        />
      </Source>

    </>

  )
}
