import { useState } from 'react'
import { useAppDispatch } from 'utils'
import FormControl from '@mui/material/FormControl'
import {
  FlyToInterpolator,
} from 'react-map-gl'
import { updateViewport, setSelectRegionId } from 'reducers/map/slices'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import adminRegion from 'assets/mapstyles/region_admin.json'
import './style.scss'

const DATA = adminRegion.features.map(feature => feature.properties)

function RegionSelect() {
  const dispatch = useAppDispatch()
  const [region, setRegion] = useState<string>('france')

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value === 'france') {
      setRegion('france')
      dispatch(setSelectRegionId(''))
      dispatch(updateViewport({
        latitude: 46.8025,
        longitude: 2.7643,
        zoom: 5.4389429465554,
        bearing: 0,
        pitch: 0,
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator(),
      }))
    } else {
      setRegion(event.target.value)
      dispatch(setSelectRegionId(event.target.value))
    }
  }

  const handleClear = () => {
    setRegion('')
    dispatch(setSelectRegionId(''))
  }

  return (
    <div className="region-select">
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <Select
          autoWidth
          variant="outlined"
          value={region}
          onChange={handleChange}
        >
          <MenuItem value="france">
            France
          </MenuItem>
          {DATA.map(item => (
            <MenuItem key={item.reg} value={item.libgeo}>
              {item.libgeo}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default RegionSelect
