import moment from 'moment'
import 'moment/locale/fr'
import nextId from 'react-id-generator'
import { useAppSelector, useAppDispatch } from 'utils'
import { ReactElement, useState } from 'react'
import { updateObject } from 'reducers/map/slices'
import Avatar from '@mui/material/Avatar'
import './style.scss'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Checkbox from '@mui/material/Checkbox'
import { colorAtt, iconAtt } from './utils'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row',
  minHeight: '48px',
  paddingLeft: 0,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    margin: 2,
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(0),
}))

export default function Legend(): ReactElement {
  const dispatch = useAppDispatch()
  const [expanded, setExpanded] = useState<string | false>('panel1')
  const { dataMap } = useAppSelector(state => state.projects)

  function regroupArrayByUniqueValue(array, key) {
    return array.reduce((grouped, item) => {
      const value = item.properties[key];
      (grouped[value] = grouped[value] || []).push(item)
      return grouped
    }, {})
  }

  const regroupedDataByName = regroupArrayByUniqueValue(dataMap?.features, 'famille')
  const result = Object.entries(regroupedDataByName).map(el => ({ id: el[0], features: [...el[1] as any] }))
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    event.stopPropagation()
    setExpanded(newExpanded ? panel : false)
  }

  const flyTo = object => {
    dispatch(updateObject(object))
  }

  return (
    <div className="legend-wrapper">
      {result && result.map((x, idx) => (
        <div className="category" key={nextId()}>
          <Accordion
            expanded={expanded === x.id}
            onChange={handleChange(x.id)}
          >
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <div className="title" style={{ color: colorAtt(x.id) }}>
                {iconAtt(x.id)}
                {x.id}
              </div>
            </AccordionSummary>
            {expanded === x.id && (
            <AccordionDetails>
              {x.features.map((i, index) => (
                <div className="item" key={nextId()} onClick={() => flyTo(i.geometry)}>
                  <Avatar
                    className="order"
                    style={{ backgroundColor: colorAtt(x.id) }}
                  >
                    {index}
                  </Avatar>
                  <div className="description-wrapper">
                    <span className="description">{i.properties.nom_gare}</span>
                    <span className="timeline">
                      { i.properties.date_fin ? `${moment(i.properties.date_debut).format('DD MMMM YYYY')}` : ''}
                      {' '}
                      { i.properties.date_fin ? 'au' : ''}
                      {' '}
                      { i.properties.date_fin ? `${moment(i.properties.date_fin).format('DD MMMM YYYY')}` : ''}
                      <span className="budget">
                        {' '}
                        { i.properties.date_fin ? '-' : ''}
                        {' '}
                        {`${Intl.NumberFormat('fr', { notation: 'compact' }).format(i.properties.budget)}`}
                        {' '}
                        (
                        { i.geometry.type}
                        )
                      </span>
                    </span>
                  </div>
                </div>
              ))}
            </AccordionDetails>
            )}

          </Accordion>
        </div>
      ))}

    </div>
  )
}
