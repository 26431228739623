/* eslint-disable max-len */
import { FAMILY_CONSTRUCTIONS } from 'components/layers/const'
import { FeatureCollection } from 'geojson'
import { useAppSelector } from 'utils'
import { ReactElement } from 'react'
import { Layer, Source } from 'react-map-gl'
import chantierLayerPaint from './paint'

export default function ChantierLayer(): ReactElement {
  const { lineWidth, pointWidth, objectFiltered } = useAppSelector(state => state.map)
  const { dataMap } = useAppSelector(state => state.projects)

  function buildFilter(arr, type) {
    const filter = ['in', 'id']

    if (arr.length === 0) {
      return ['==', '$type', type]
    }

    for (let i = 0; i < arr.length; i += 1) {
      filter.push(arr[i])
    }

    return filter
  }

  const myFilter = type => buildFilter(objectFiltered, type)
  return (
    <Source
      type="geojson"
      data={dataMap as FeatureCollection}
    >
      <Layer
        {...chantierLayerPaint.lineLayer}
        filter={['all',
          ['==', '$type', 'LineString'],
          myFilter('LineString')]}
        paint={{
          'line-color': ['case',
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.adv.label], FAMILY_CONSTRUCTIONS.adv.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.development.label], FAMILY_CONSTRUCTIONS.development.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.station.label], FAMILY_CONSTRUCTIONS.station.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.electric.label], FAMILY_CONSTRUCTIONS.electric.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.environment.label], FAMILY_CONSTRUCTIONS.environment.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.structure.label], FAMILY_CONSTRUCTIONS.structure.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.maintenance.label], FAMILY_CONSTRUCTIONS.maintenance.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.landStructure.label], FAMILY_CONSTRUCTIONS.landStructure.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.handicap.label], FAMILY_CONSTRUCTIONS.handicap.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.signal.label], FAMILY_CONSTRUCTIONS.signal.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.reliability.label], FAMILY_CONSTRUCTIONS.reliability.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.security.label], FAMILY_CONSTRUCTIONS.security.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.telecom.label], FAMILY_CONSTRUCTIONS.telecom.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.track.label], FAMILY_CONSTRUCTIONS.track.color,
            '#154734',
          ],
          'line-width': {
            stops: [
              [6, lineWidth[0]],
              [8, lineWidth[1]],
            ],
          },
        }}
      />
      <Layer
        {...chantierLayerPaint.pointLayer}
        filter={['all',
          ['==', '$type', 'Point'],
          myFilter('Point')]}
        paint={{
          'circle-color': ['case',
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.adv.label], FAMILY_CONSTRUCTIONS.adv.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.development.label], FAMILY_CONSTRUCTIONS.development.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.station.label], FAMILY_CONSTRUCTIONS.station.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.electric.label], FAMILY_CONSTRUCTIONS.electric.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.environment.label], FAMILY_CONSTRUCTIONS.environment.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.structure.label], FAMILY_CONSTRUCTIONS.structure.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.maintenance.label], FAMILY_CONSTRUCTIONS.maintenance.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.landStructure.label], FAMILY_CONSTRUCTIONS.landStructure.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.handicap.label], FAMILY_CONSTRUCTIONS.handicap.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.signal.label], FAMILY_CONSTRUCTIONS.signal.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.reliability.label], FAMILY_CONSTRUCTIONS.reliability.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.security.label], FAMILY_CONSTRUCTIONS.security.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.telecom.label], FAMILY_CONSTRUCTIONS.telecom.color,
            ['==', ['get', 'famille'], FAMILY_CONSTRUCTIONS.track.label], FAMILY_CONSTRUCTIONS.track.color,
            '#154734',
          ],
          'circle-stroke-color': '#FFF',
          'circle-stroke-width': 1.5,
          'circle-radius': {
            stops: [
              [6, pointWidth[0]],
              [8, pointWidth[1]],
            ],
          },
        }}
      />
    </Source>
  )
}
