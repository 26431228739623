import SettingsIcon from '@mui/icons-material/Settings'

enum MenuKeys {
  styleMap = 'styleMap',
}

const MENU_ITEMS = (toggleDisplayMenu: (k: MenuKeys) => void) => [
  {
    key: MenuKeys.styleMap,
    onClick: () => toggleDisplayMenu(MenuKeys.styleMap),
    icon: <SettingsIcon />,
  },
]

export { MenuKeys, MENU_ITEMS }
