import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { getProject } from 'reducers/projects/thunks'
import { closeSideMenu } from 'reducers/app'
import { changePanel } from 'reducers/projects/slices'
import { useAppDispatch, useAppSelector } from 'utils'
import { ActivePanel, ProjectViewer } from 'types'
import { topBarTitle } from 'components/topBar'
import Header from './header'
import MapGL from './map'
import Editor from './editor'
import Config from './config'

import './style.scss'

function Portefeuille() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams<{slug: string}>()
  const { list, onglet, dataMap } = useAppSelector(state => state.projects)
  const project = list.find(({ slug }) => slug === params.slug) as ProjectViewer

  topBarTitle.value = project?.name

  useEffect(() => {
    dispatch(closeSideMenu())
    // dispatch(getProject(params.slug))
  }, [])

  const handleChangePanel = (action: ActivePanel) => {
    dispatch(changePanel(action))
  }

  useEffect(() => {
    if (dataMap === null) {
      navigate('/')
    }
  }, [dataMap])

  return (
    <div id="project">
      <Header active={onglet} onChangePanel={handleChangePanel} />
      <div className="body-wrapper">
        {(onglet === ActivePanel.CODE && dataMap) && <Editor />}
        {(onglet === ActivePanel.CONFIG && dataMap) && <Config />}
        {(onglet === ActivePanel.CODE && dataMap) && <MapGL />}
      </div>

    </div>
  )
}

export default Portefeuille
