import config from 'config/config'
import { ReactComponent as Aiguillage } from 'assets/icons/aiguillage.svg'
import { ReactComponent as Catenaire } from 'assets/icons/catenaire.svg'
import { ReactComponent as Dev } from 'assets/icons/dev.svg'
import { ReactComponent as Gare } from 'assets/icons/gare.svg'
import { ReactComponent as Inno } from 'assets/icons/inno.svg'
import { ReactComponent as Ouvrage } from 'assets/icons/ouvrage.svg'
import { ReactComponent as Signal } from 'assets/icons/signal.svg'
import { ReactComponent as Voie } from 'assets/icons/voie.svg'
import Crop169Icon from '@mui/icons-material/Crop169'
import GppGoodIcon from '@mui/icons-material/GppGood'
import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices'
import RecyclingIcon from '@mui/icons-material/Recycling'
import SecurityIcon from '@mui/icons-material/Security'

const MAP_LAYER_SOURCE = {
  track: 'cassini_v2_gaia_habillage_valeur_margin_100',
}

const LAYER_NAMES = {
  trackLayer: 'track-layer',
  regionLayer: 'region-layer',
  chantierLayer: 'chantier-layer',
}

const LAYER_URL = `${config.proxy}/chartis/v2/layer`

const FAMILY_CONSTRUCTIONS = {
  adv: {
    color: '#80c342',
    logo: <Aiguillage />,
    label: 'appareil de voie',
  },
  development: {
    color: '#6c1b78',
    logo: <Dev />,
    label: 'développement',
  },
  station: {
    color: '#ed145b',
    logo: <Gare />,
    label: 'Gares & Connexions',
  },
  electric: {
    color: '#c5251c',
    logo: <Catenaire />,
    label: 'installation fixe de traction électrique',
  },
  environment: {
    color: '#6558B1',
    logo: <RecyclingIcon />,
    label: 'mise aux normes environnementales',
  },
  structure: {
    color: '#f2a917',
    logo: <Ouvrage />,
    label: 'ouvrage d\'art',
  },
  maintenance: {
    color: '#00B388',
    logo: <CleaningServicesIcon />,
    label: 'opération de grand entretien',
  },
  landStructure: {
    color: '#f2a917',
    logo: <Crop169Icon />,
    label: 'ouvrage en terre',
  },
  handicap: {
    color: '#4F2910',
    logo: <AccessibleForwardIcon />,
    label: 'Personne a mobilité réduite et passage a niveau',
  },
  signal: {
    color: '#93827b',
    logo: <Signal />,
    label: 'signalisation',
  },
  reliability: {
    color: '#3F2A56',
    logo: <GppGoodIcon />,
    label: 'sureté',
  },
  security: {
    color: '#303383',
    logo: <SecurityIcon />,
    label: 'sécurité',
  },
  telecom: {
    color: '#303383',
    logo: <LocalPhoneIcon />,
    label: 'télécom',
  },
  track: {
    color: '#00aaad',
    logo: <Voie />,
    label: 'voie',
  },
}

export {
  MAP_LAYER_SOURCE, LAYER_URL, LAYER_NAMES, FAMILY_CONSTRUCTIONS,
}
