import { signal } from '@preact/signals-react'
import { PORTAL_URL } from 'config/config'
import { toggleSideMenu } from 'reducers/app'
import dgexLogo from 'assets/logos/dgex.svg'
import pdiLogo from 'assets/logos/pdi.svg'
import { useAppDispatch, useAppSelector } from 'utils'
import Burger from './burger'
import AccountMenu from './menu'

import './style.scss'

type Props = {
  appName: string;
}

export const topBarTitle = signal<string>('')

export default function TopBar({ appName }: Props) {
  const { sideMenuOpened } = useAppSelector(state => state.app)
  const dispatch = useAppDispatch()
  const toggleMenu = () => dispatch(toggleSideMenu())

  return (
    <header>
      <Burger opened={sideMenuOpened} action={toggleMenu} />
      <div className="logos">
        <a className="flex-center" href={PORTAL_URL.url}>
          <img alt={appName} src={dgexLogo} />
        </a>
        <img alt={appName} src={pdiLogo} />
      </div>
      <p>{topBarTitle.value}</p>
      <AccountMenu />
    </header>
  )
}
