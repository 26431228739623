import { useEffect, useState } from 'react'
import { normalize, useAppDispatch, useAppSelector } from 'utils'
import { getProjects } from 'reducers/projects/thunks'
import terms from 'common/terms'
import DropDown, { DropDownType } from 'components/dropDown/DropDown'
import MenuDropdown from 'components/menuDropDown/MenuDropdown'
import { topBarTitle } from 'components/topBar'
import Card from './card'
import SearchBar from './search'

import './style.scss'

export default function Portefeuilles() {
  topBarTitle.value = terms.Pages.Projects.title

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [filter, setFilter] = useState<'DATE' | 'ALPHA' | null>(null)
  const [search, setSearch] = useState('')
  const { sideMenuOpened } = useAppSelector(state => state.app)
  const { list } = useAppSelector(state => state.projects)
  const dispatch = useAppDispatch()

  useEffect(() => {
    // dispatch(getProjects())
  }, [])

  const dropdownActions = [
    {
      label: terms.Pages.Projects.dropdownLabels.date,
      handleClick: () => setFilter('DATE'),
    },
    {
      label: terms.Pages.Projects.dropdownLabels.alpha,
      handleClick: () => setFilter('ALPHA'),
    },
  ]

  const getFilteredProjects = () => {
    let filteredList = [...list]

    if (filter === 'DATE') {
      filteredList.sort((a, b) => {
        if (a.last_modified < b.last_modified) return 1
        if (a.last_modified > b.last_modified) return -1
        return 0
      })
    } else if (filter === 'ALPHA') {
      filteredList.sort((a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })
    }

    if (search) {
      filteredList = filteredList.filter(({ name }) => normalize(name).includes(normalize(search)))
    }

    return filteredList
  }

  return (
    <div id="projects" className={`pages ${sideMenuOpened ? 'side-menu-opened' : ''}`}>
      <div className="actions">
        <SearchBar
          placeholder={terms.Pages.Projects.searchPlaceholder}
          onChange={(value: string) => setSearch(value)}
        />
        <DropDown
          type={DropDownType.CHEVRON}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          label={terms.Common.sortBy}
        >
          <MenuDropdown actions={dropdownActions} handleClose={() => setAnchorEl(null)} />
        </DropDown>
      </div>
      <div className="wrapper">
        {getFilteredProjects().map(project => <Card key={project.slug} project={project} />)}
      </div>
    </div>
  )
}
