/* eslint-disable max-len */
import MapIcon from '@mui/icons-material/Map'
import TableChartIcon from '@mui/icons-material/TableChart'
import { ActivePanel } from 'types'
import RegionSelect from 'components/regionSelect'

import Action from './action'
import './style.scss'

type Props = {
  active: ActivePanel
  onChangePanel: (action: ActivePanel) => void
}

export default function Header({ active, onChangePanel }: Props) {
  const handleDisplayPanel = (action: ActivePanel) => () => {
    onChangePanel(action)
  }

  return (
    <div className="project-header">
      <Action Icon={MapIcon} action={handleDisplayPanel(ActivePanel.CODE)} active={active === ActivePanel.CODE} />
      <Action Icon={TableChartIcon} action={handleDisplayPanel(ActivePanel.CONFIG)} active={active === ActivePanel.CONFIG} />
      <RegionSelect />
    </div>
  )
}
