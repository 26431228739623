/* eslint-disable max-len */
import {
  MutableRefObject, ReactElement, Ref, useEffect, useRef, useState,
} from 'react'
import ReactMapGL, {
  MapRef, ViewportProps, MapEvent,
  FlyToInterpolator, WebMercatorViewport,
} from 'react-map-gl'
import {
  bbox,
} from '@turf/turf'
import { updateViewport, updateObject } from 'reducers/map/slices'
import lightOsmTheme from 'assets/mapstyles/light.json'
import { useAppDispatch, useAppSelector } from 'utils'
import { transformRequest } from 'services'
import TrackLayer from 'components/layers/track'
import RegionLayer from 'components/layers/region'
import ChantierLayer from 'components/layers/chantier'
import { LAYER_NAMES } from 'components/layers/const'
import Toolbar from './toolbar'
import FeatureClickPopup from './popup'

import './style.scss'

export default function MapGL(): ReactElement {
  const mapRef: MutableRefObject<MapRef | undefined> | undefined = useRef()
  const { viewport, object } = useAppSelector(state => state.map)
  const [openPopup, setPopup] = useState(false)
  const [popupEvent, setPopupEvent] = useState<MapEvent>()
  const dispatch = useAppDispatch()
  const mapDOMRef = useRef(null)

  const onViewportChange = (newViewport: ViewportProps) => {
    dispatch(updateViewport({ ...newViewport, transitionDuration: 0 }))
  }

  useEffect(() => {
    if (object?.type === 'Point') {
      dispatch(updateViewport({
        ...viewport,
        zoom: 15,
        latitude: object.coordinates[1],
        longitude: object.coordinates[0],
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator(),
      }))
      dispatch(updateObject(null))
    } else if (object) {
      const objectBbox = bbox(object)
      const { longitude, latitude, zoom } = new WebMercatorViewport(viewport as { width: number, height: number })
        .fitBounds([[objectBbox[0], objectBbox[1]], [objectBbox[2], objectBbox[3]]], {
          padding: {
            top: 20, left: 300, right: 20, bottom: 20,
          },
        })
      dispatch(updateViewport({
        ...viewport,
        zoom,
        latitude,
        longitude,
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator(),
      }))
      dispatch(updateObject(null))
    }
  }, [object])

  const onFeatureClick = (e: MapEvent) => {
    if (e.features[0]
      && (e.features[0].layer.id === LAYER_NAMES.chantierLayer || `${LAYER_NAMES.chantierLayer}-point`)) {
      setPopupEvent(e)
      setPopup(true)
    }
  }

  return (
    <div className="map-gl" ref={mapDOMRef}>
      <ReactMapGL
        {...viewport}
        ref={mapRef as Ref<MapRef>}
        width="100%"
        height="100%"
        transformRequest={transformRequest}
        mapStyle={lightOsmTheme}
        onViewportChange={onViewportChange}
        onClick={onFeatureClick}
        interactiveLayerIds={[LAYER_NAMES.chantierLayer, `${LAYER_NAMES.chantierLayer}-point`]}
      >
        <Toolbar />
        {openPopup && popupEvent && <FeatureClickPopup event={popupEvent} onClose={() => setPopup(false)} />}
        <TrackLayer />
        <RegionLayer />
        <ChantierLayer />
      </ReactMapGL>
    </div>
  )
}
